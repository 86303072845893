<!-- <app-header *ngIf="!isAdmin"></app-header> -->
<app-admin-header *ngIf="role && role?.toLowerCase() === 'admin'"></app-admin-header>

<!-- <div class="user-layout page-wrapper" [class.admin_layout] = "role && role?.toLowerCase() === 'admin'">
	<div class="content container-fluid">
		<router-outlet></router-outlet>
	</div>
</div>  -->

<div class="page-wrapper " [class.admin_layout] = "role && role.toLowerCase() === 'admin'">
	<app-side-bar *ngIf="!isAdmin"></app-side-bar>
	<div class="content container-fluid">
		<router-outlet></router-outlet>
	</div>
</div>