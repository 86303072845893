import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { TutorProfileService } from 'src/app/views/post-login/tutor/tutor-profile/tutor-profile.service';
import { StorageService } from '../services/storage-service/storage-service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PrivacyComponent } from 'src/app/views/pre-login/home/privacy/privacy.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
  modalRef  !: BsModalRef;
  splitVal: any;
  base = '';
  page = '';
  userDetails: any;
  privacyPolicy: string = '';

  constructor(
    public router: Router,
    private storageService: StorageService,
    private tutorProfileService: TutorProfileService,
    private modalService: BsModalService,
    private modalService1: NgbModal,

  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
      }
    });
  }

  ngOnInit(): void {
    this.getUserDeatails()
  }


  getUserDeatails() {
    this.tutorProfileService.getUserDetails(this.storageService.getDataFromLocalStorage('id')).subscribe((res) => {
      this.userDetails = res.existingUser;
    })
  }

  change(name: any) {
    this.page = name;
  }

  ngAfterViewInit() {
    this.loadDynmicallyScript('assets/admin/js/script.js');
  }
  loadDynmicallyScript(js: any) {
    var script = document.createElement("script");
    script.src = js;
    script.async = false;
    document.head.appendChild(script);
    script.onload = () => this.doSomethingWhenScriptIsLoaded();
  }
  doSomethingWhenScriptIsLoaded() { }

  onLogout() {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered', initialState: { confirmMessage: 'Are you sure want to logout?' },
      backdrop: 'static', 
      keyboard: false  
      
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(result => {
      if (result) {
        this.storageService.clearLocalStorage();
        this.router.navigate(['/home']);
        
      }
    });
  }

  openPrivacyPolicyModal() {
    const dialogRef = this.modalService1.open(PrivacyComponent,  { size: 'lg' });
      // data: { privacyPolicy: this.PrivacyComponent } 
      dialogRef.componentInstance.privacyPolicy = this.privacyPolicy;
    
  }

}
