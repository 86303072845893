import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDetailsService } from 'src/app/shared/services/company-details.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  @Input() privacyPolicy: string = '';
  isModalOpen : boolean = true;
scrollup: any;

  constructor(
    private router:Router,
    private companyDetailsService: CompanyDetailsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.getPrivacyPolicy();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
});

this.scrollup.window.scroll({
   top: 0,
      left: 0,
      behavior: 'smooth'

})

  }

  navigateToContactUs(){
    this.router.navigate(['common/contactUs']);
  }

  getPrivacyPolicy(){
    this.companyDetailsService.getPrivacyPolicy().subscribe((res: any)=>{
        this.privacyPolicy = res.privacyPolicy[0].content
      })
  }

  closeModal(): void {
    // Logic to close the modal
    this.isModalOpen = false;
  }

}
