import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage-service/storage-service';
import { TutorProfileService } from 'src/app/views/post-login/tutor/tutor-profile/tutor-profile.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { SharedService } from '../services/shared-service/shared.service';
import { StudentProfileService } from 'src/app/views/post-login/student/student-profile/student-profile.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  modalRef!: BsModalRef;
  base = '';
  page = '';
  splitVal: any;
  isLoginShow: boolean = true;
  userDetails: any;
  profilePicture!: string;
  userId: any;
  alertList: any = [];
  isStudent: boolean = false;
  activeButton: string = 'login';

  constructor(
    private storageService: StorageService,
    private tutorProfileService: TutorProfileService,
    private alertService: AlertService,
    private studentProfileService: StudentProfileService,
    private router: Router,
    private modalService: BsModalService,
    public sharedService: SharedService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
      }
    });
  }

  ngOnInit(): void {
    this.studentProfileService.getProfilePicture().subscribe((res: string) => {
      this.profilePicture = res;
    });
    let role = this.storageService.getDataFromLocalStorage('role');
    this.userId = this.storageService.getDataFromLocalStorage('id');
    if (role?.toLowerCase() === 'student' || role?.toLowerCase() === 'tutor') {
      this.isLoginShow = false;
      this.getUserDeatails();
    }
    if (role && role?.toLowerCase() === 'student') {
      this.isStudent = true;
    }

    this.getUserNotification();
  }

  setActive(button: string) {
    this.activeButton = button;
  }
  getUserDeatails() {
    this.tutorProfileService
      .getUserDetails(this.storageService.getDataFromLocalStorage('id'))
      .subscribe((res: any) => {
        this.userDetails = res.existingUser;
      });
  }

  change(name: any) {
    this.page = name;
  }

  onLogout() {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: 'Are you sure want to logout?' },
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result: any) => {
        if (result) {
          this.storageService.clearLocalStorage();
          this.router.navigate(['/home']);
          this.isLoginShow = true;
        }
      }
    );
  }

  navigateToTutorRegistration() {
    this.router.navigate(['/register'], { queryParams: { as: 'tutor' } });
  }

  navigateToStudentRegistration() {
    this.router.navigate(['/register'], { queryParams: { as: 'student' } });
  }

  navigateToCoursesPage() {
    this.router.navigate(['courses/all']);
  }

  navigateToAlertDetails(alertDetails: any) {
    this.deleteSelectedNotification(alertDetails._id);
    if (alertDetails.type === 'Schedule') {
      this.router.navigate(
        [`/${this.isStudent ? 'student' : 'tutor'}/schedules/details`],
        {
          queryParams: { sid: alertDetails.data.scheduleId },
        }
      );
    }
  }

  getUserNotification() {
    if (this.userId) {
      this.alertService
        .getUserNotification(this.userId)
        .subscribe((res: any) => {
          this.alertList = res.alerts.alerts.reverse();
        });
    }
  }

  deleteAllNotifications() {
    this.alertService.deleteNotifications(this.userId).subscribe((res: any) => {
      this.getUserNotification();
    });
  }

  deleteSelectedNotification(alertId: string) {
    this.alertService.deleteSelectedNotification(alertId).subscribe((res) => {
      this.getUserNotification();
    });
  }

  navigateToHome() {
    this.router.navigate(['home']);
  }

  navigateToAboutus() {
    this.router.navigate(['home/about-us']);
  }

  navigateToContactus() {
    this.router.navigate(['common/contactUs']);
  }
}
