import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RoleDirective } from './directives/role.directive';
import { RouterModule } from '@angular/router';
import { ChipInputComponent } from './components/chip-input/chip-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { TimeZoneConverterPipe } from './pipes/time-zone-converter.pipe';
import { DateTimeZoneConverterPipe } from './pipes/date-time-zone-converter.pipe';



@NgModule({
  declarations: [
    RoleDirective,
    ChipInputComponent,
    FooterComponent,
    TimeZoneConverterPipe,
    DateTimeZoneConverterPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports : [
    RoleDirective,
    ChipInputComponent,
    FooterComponent,
    TimeZoneConverterPipe,
    DateTimeZoneConverterPipe
  ],
  providers:[DatePipe]
})
export class SharedModule { }
