import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpDataService } from '../http-data/http-data.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private httpDataService:HttpDataService
  ) { }

  getAllCourses():Observable<any>{
    return this.httpDataService.get(`course`)
  }

  getUserDetails(id:string):Observable<any>{
    return this.httpDataService.get(`user/${id}`)
  }

  resetPassword(payload:any):Observable<any>{
    return this.httpDataService.authPost(`auth/resetPassword`,payload);
  }

  getTutorProfileDetailsById(id : string):Observable<any>{
    return this.httpDataService.get(`tutorProfile/${id}`);
  }

  getTutorProfileRatingsById(id : string):Observable<any>{
    return this.httpDataService.get(`ratings/metrics/${id}`);
  }
  
  getUserDetailsById(id : string):Observable<any>{
    return this.httpDataService.get(`tutorProfile/userId/${id}`);
  }

  getNameInitials(firstname: string, lastName?: string): string {
    if (firstname && lastName){
      return (
        firstname.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
      );
    } else if(firstname){
      return (
        firstname.charAt(0).toUpperCase() + firstname.charAt(1).toUpperCase()
      );
    }
    else return '';
  }
}
