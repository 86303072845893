import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.css']
})
export class ChipInputComponent implements OnInit,OnChanges {

  @Output() addedChips: EventEmitter<any> = new EventEmitter<any>();
  @Input() chips: string[] = [];
  @Input() suggestions: string[] =[]
  chipInput: string = '';
  suggestionInput: string = '';
  filteredSuggestions: string[] = [];
  selectedIndex: number = -1;

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['suggestions']?.currentValue) {
      this.suggestions = changes['suggestions']?.currentValue;
    }
    if (changes['chips']?.currentValue) {
      this.chips = changes['chips']?.currentValue;
    }
  }

  addChip(): void {
    if (this.chipInput && !this.chips.includes(this.chipInput)) {
      this.chips.push(this.chipInput);
      this.addedChips.emit(this.chips);
      this.resetInput();
    }
  }

  removeChip(index: number): void {
    this.chips.splice(index, 1);
    this.addedChips.emit(this.chips);
  }


  filterSuggestions(event: KeyboardEvent): void {
    const inputValue = this.chipInput.toLowerCase();
    this.filteredSuggestions = this.suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue)
    );

    if (event.key === 'ArrowDown') {
      this.selectedIndex = (this.selectedIndex + 1) % this.filteredSuggestions.length;
      event.preventDefault(); 
    } else if (event.key === 'ArrowUp') {
      this.selectedIndex = (this.selectedIndex - 1 + this.filteredSuggestions.length) % this.filteredSuggestions.length;
      event.preventDefault(); 
    } else if (event.key === 'Enter') {
      if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredSuggestions.length) {
        this.addSuggestion(this.filteredSuggestions[this.selectedIndex]);
      } else {
        this.addChip();
      }
    } else {
      this.selectedIndex = -1; 
    }
  }

  addSuggestion(suggestion: string): void {
    if (!this.chips.includes(suggestion)) {
      this.chips.push(suggestion);
      this.addedChips.emit(this.chips);
    }
    this.resetInput();
  }

  resetInput(): void {
    this.chipInput = '';
    this.filteredSuggestions = [];
    this.selectedIndex = -1;
  }
}
