import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpDataService } from 'src/app/shared/services/http-data/http-data.service';
import { SchedulesBySearchByCriteriaPayload } from '../../Interfaces/schedules-interface';

@Injectable({
  providedIn: 'root',
})
export class SchedulesService {
  constructor(private httpDataService: HttpDataService) {}

  getAllCourses(): Observable<any> {
    return this.httpDataService.get(`course`);
  }

  getSchedulesBySearchByCriteria(
    payload: SchedulesBySearchByCriteriaPayload
  ): Observable<any> {
    return this.httpDataService.post(`schedule/searchByCriteria`, payload);
  }

  updateSchedule(id: string, payload: any): Observable<any> {
    return this.httpDataService.put(`schedule/${id}`, payload);
  }

  addAttachments(id: string, payload: any): Observable<any> {
    return this.httpDataService.post(`schedule/attachments/${id}`, payload);
  }

  addNotes(id: string, payload: any): Observable<any> {
    return this.httpDataService.post(`schedule/notes/${id}`, payload);
  }

  getSchedulesByScheduleId(scheduleId: string): Observable<any> {
    return this.httpDataService.get(`schedule/${scheduleId}`);
  }

  updateAcceptStatus(payload: { id: string; amount: number }): Observable<any> {
    return this.httpDataService.post(`schedule/acceptSchdule`, payload);
  }

  getTutorScheduleCount(): Observable<any> {
    return this.httpDataService.get(`schedule/tutor-schedule-counts`);
  }

  getStudentScheduleCount(): Observable<any> {
    return this.httpDataService.get(`schedule/student-schedule-counts`);
  }

  getAdminScheduleCount(): Observable<any> {
    return this.httpDataService.get(`schedule/admin-schedule-counts`);
  }

  getStudentScheduleCountById(studentId: any): Observable<any> {
    return this.httpDataService.get(
      `schedule/student-schedule-counts?student=${studentId}`
    );
  }
  getEnrolledCourseCountById(studentId: string): Observable<any> {
    return this.httpDataService.get(
      `course-enrollments/${studentId}`
    );
  }

  getTutorScheduleCountById(tutorId: string): Observable<any> {
    return this.httpDataService.get(
      `schedule/tutor-schedule-counts?tutor=${tutorId}`
    );
  }

  getLatestUpdatedSchedulesById(loginedUserId: String): Observable<any> {
    return this.httpDataService.get(
      `schedule/latest-updated-schedules/${loginedUserId}`
    );
  }
  getLatestUpdatedSchedulesBytutorId(tutorId: String): Observable<any> {
    return this.httpDataService.get(
      `schedule/latest-updated-schedules/tutor${tutorId}`
    );
  }

  cancelSchedule(payload:any):Observable<any>{
    return this.httpDataService.post('schedule/cancelSchdule',payload)
  }

  rejectSchedule(payload:any):Observable<any>{
    return this.httpDataService.post('schedule/rejectSchdule',payload)
  }

  getTotalEarned(tutorId:string):Observable<any>{
    return this.httpDataService.get(`payments/payout/totalAmount/${tutorId}`)
  }

  getTotalTutoringHours(tutorId:string):Observable<any>{
    return this.httpDataService.get(`schedule/tutors-schedule-hours/${tutorId}`)
  }

  requestSchedule(id : string):Observable<any>{
    return this.httpDataService.post(`schedule/requestSchedule/${id}`,{})
  }

  getTutorPayoutsStatistics(year:number, tutorId:string):Observable<any>{
    return this.httpDataService.get(`payments/tutorPayoutStatistics/${year}/${tutorId}`)
  }

  completeSchedule(scheduleId: string): Observable<any>{
    return this.httpDataService.put(`schedule/completed/${scheduleId}`,{})
  }
}
