import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage-service/storage-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TutorProfileService } from 'src/app/views/post-login/tutor/tutor-profile/tutor-profile.service';
import { STORAGE_KEYS } from '../enums/storage.enum';
import { AlertService } from '../services/alert.service';
import { SocialLoginService } from '../services/social-login/social-login.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent implements OnInit {
  modalRef!: BsModalRef;
  isLoginShow: boolean = true;
  userType!: string;
  userDetails: any;
  page = 'dashboard';
  isMiniSidenav: boolean = false;
  alertList: any = [];
  userId:string=''
  notificationPanel:number= 0;
  unreadCount:number=0;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private modalService: BsModalService,
    private tutorProfileService: TutorProfileService,
    private alertService: AlertService,
    private socialLoginService: SocialLoginService,
  ) {
    this.userId = this.storageService.getDataFromLocalStorage(STORAGE_KEYS.USER_ID);
  }

  ngOnInit(): void {
    this. getUserDeatails();
    this.getUserNotification();
  }

  getUserDeatails() {
    this.tutorProfileService.getUserDetails(this.storageService.getDataFromLocalStorage('id')).subscribe((res: any) => {
      this.userDetails = res.existingUser;
    })
  }

  getUserNotification() {
    if(this.userId){
      this.alertService.getUserNotification(this.userId).subscribe((res: any) => {
        this.alertList = res.alerts.alerts.reverse();
        this.unreadCount  = res.alerts.unreadCount;
      });
    }
  }

  deleteAllNotifications() {
    this.alertService
      .deleteNotifications(this.userId)
      .subscribe((res: any) => {
        this.getUserNotification()
      });
  }

  deleteSelectedNotification(alertId:string){
    this.alertService.deleteSelectedNotification(alertId).subscribe(res=>{
      this.getUserNotification()
    })
  }

  navigateToAlertDetails(alertDetails: any) {
    this.deleteSelectedNotification(alertDetails._id)
    if (alertDetails.type === 'Schedule') {
      this.router.navigate([`/${this.userDetails?.roles[0] === 'Student' ? 'student':'tutor'}/schedules/details`], {
        queryParams: { sid: alertDetails.data.scheduleId },
      });
    }
  }

  showNotificationPanel(id:number){
    if(id === this.notificationPanel){
      this.notificationPanel = 0
    }else{
      this.notificationPanel = id;
    }
  }

  closeNotificationPanel() {
    this.notificationPanel = 0; // Reset to 0 to indicate no panel is open
  }

  navigateToHomepage(){
    if(this.userDetails?.roles[0] === 'Tutor'){
      return;
    }
    this.router.navigate(['home']);
  }


  onLogout() {
    // Display the confirmation dialog
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: 'Are you sure you want to logout?' },
      backdrop: 'static', // Prevent modal from closing on backdrop click
      keyboard: false
    });

    // Handle the confirmation dialog response
    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result: any) => {
        if (result) {
          // Perform logout via API
          const loginDetails = { token: this.storageService.get('googleAccessToken') }; // Replace with actual token retrieval

          this.socialLoginService.logoutWithGoogleApi(loginDetails).subscribe({
            next: () => {
              console.log('Successfully logged out from Google');
              // Clear local storage and navigate to home
              this.storageService.clearLocalStorage();
              this.router.navigate(['/home']);
              this.isLoginShow = true;
            },
            error: (error: any) => {
              console.error('Error logging out from Google:', error);
              // Fallback to local logout even if API fails
              this.storageService.clearLocalStorage();
              this.router.navigate(['/home']);
              this.isLoginShow = true;
            }
          });
        }
      }
    );
  }

 

  change(name: any) {
    this.page = name;
  }
}
