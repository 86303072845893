import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StudentSchedulesService {

  constructor() { 
  }
  selectedScheduleDetails: any;
  amountToBePaid: number = 0;

  updateSelectedScheduleDetails(details: any) {
    this.selectedScheduleDetails = details;
  }
  updateAmount(amount: number) {
    this.amountToBePaid = amount;
  }
}
