import { Component, OnInit } from '@angular/core';
import {
  Event,
  NavigationStart,
  Router,
  ActivatedRoute,
  Params,
  NavigationEnd,
} from '@angular/router';
import { STORAGE_KEYS } from 'src/app/shared/enums/storage.enum';
import { AttributeService } from 'src/app/shared/services/storage-service/attribute.service';
import { StorageService } from 'src/app/shared/services/storage-service/storage-service';

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.css']
})
export class PostLoginComponent implements OnInit {

  isAdmin: boolean = false;
  constructor(
    public Router: Router,
    public Attributeservice: AttributeService,
    private storageService:StorageService
  ) { }

  ngOnInit(): void {
    this.Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
      }
    });
    this.Attributeservice.adminsideMenuresponsive();

    let role = this.storageService.getDataFromLocalStorage(STORAGE_KEYS.ROLE);

    if(role && role === 'Admin'){
      this.isAdmin = true
    }
  }

}
