export enum Status {
    REQUESTED = 'REQUESTED',
    ACCEPTED  = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    CONFIRMED = 'CONFIRMED'
   
}

export const statusList =[
    {
        value : 'REQUESTED',
        label : 'Requested'
    },{
        value : 'ACCEPTED',
        label : 'Accepted'
    },{
        value : 'REJECTED',
        label : 'Rejected'
    },{
        value : 'CANCELLED',
        label : 'Cancelled'
    },{
        value : 'COMPLETED',
        label : 'Completed'
    },
    {
        value : 'CONFIRMED',
        label : 'Confirmed'
    }
]