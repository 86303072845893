import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { TimeValues } from 'src/app/shared/constants/app-constants';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { Status } from 'src/app/shared/enums/schedules.enum';
import { SchedulesService } from 'src/app/shared/services/schedules-service/schedules.service';
import { SharedService } from 'src/app/shared/services/shared-service/shared.service';
import { StorageService } from 'src/app/shared/services/storage-service/storage-service';
import { StudentSchedulesService } from '../../student/student-schedules/student-schedules.service';
import { StudentProfileService } from '../../student/student-profile/student-profile.service';
import { UserService } from 'src/app/shared/services/user-service/user.service';
import { STORAGE_KEYS } from 'src/app/shared/enums/storage.enum';

@Component({
  selector: 'app-schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.css'],
})
export class ScheduleDetailsComponent implements OnInit {
  scheduleId: string = '';
  tutorDetails: any;
  scheduleDetails: any;
  timeValues = TimeValues;
  statusList = Status;
  modalRef!: BsModalRef;
  logedinUserRole: string = 'tutor';
  displayDetails: any;
  userProfilePhoto: string = '';
  isTutor = false;
  slots: { date: string; from: number; to: number }[] = [];
  totalAmountTobePaid = 0;
  tutorId: any;
  visible: boolean = false;
  action!: string;
  reason!: string;
  notes!: string;
  message!: any;
  fileAttachmments: any[] = [];
  attachments: any[] = [];
  clearAttachments!: any[];
  loggedinUserTimeZone: string = '';
  isAdmin : boolean = false;
  studentDetails: any;
  discount: number = 0;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedSchedulesService: SchedulesService,
    private studentSchedulesService: StudentSchedulesService,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private SchedulesService: SchedulesService,
    private storageService: StorageService,
    public sharedService: SharedService,
    public studentProfileService: StudentProfileService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const role = this.storageService.getDataFromLocalStorage('role');
    this.loggedinUserTimeZone = this.storageService.getDataFromLocalStorage(STORAGE_KEYS.TIME_ZONE);
    this.logedinUserRole =
      role === Roles.TUTOR
        ? 'tutor'
        : role === Roles.ADMIN
        ? 'admin'
        : 'student';
    this.isTutor = role === Roles.TUTOR ? true : false;
    this.isAdmin = role === Roles.ADMIN ? true : false;

    this.activatedRoute.queryParams.subscribe((param: any) => {
      this.scheduleId = param.sid;
    });
    if (this.scheduleId && this.scheduleId !== '') {
      this.getSchedulesDetailsByScheduleId();
    }
  }

  getSchedulesDetailsByScheduleId() {
    this.sharedSchedulesService
      .getSchedulesByScheduleId(this.scheduleId)
      .subscribe((scheduleDetails: any) => {
        this.scheduleDetails = scheduleDetails?.schedule;
        this.notes= scheduleDetails.schedule.notes
        this.tutorDetails = scheduleDetails?.schedule?.tutor[0];
        this.studentDetails = scheduleDetails?.schedule?.student[0];
        this.tutorId = this.tutorDetails._id;
        this.displayDetails =
          this.logedinUserRole === 'student'
            ? scheduleDetails?.schedule?.tutor[0]
            : scheduleDetails?.schedule?.student[0];
        this.slots = scheduleDetails?.schedule?.slots;
        this.userProfilePhoto = this.displayDetails?.photoUrl ? this.displayDetails?.photoUrl : undefined
        this.calculateTotalAmount();
        if(!this.isTutor){
          this.getUserDetails(this.tutorDetails.userId);
        }
    });
  }

  getUserDetails(userId: string){
    this.userService.getUserDetails(userId).subscribe((res: any)=>{
      if(res.existingUser?.photoUrl){
        this.userProfilePhoto= res.existingUser?.photoUrl
      }
    });
  }

  statusClass(status: string) {
    return status === Status.ACCEPTED
      ? 'confirm'
      : status === Status.REQUESTED
      ? 'pending'
      : status === Status.CANCELLED
      ? 'reject'
      : status === Status.REJECTED
      ? 'reject'
      : status === Status.COMPLETED
      ? 'accept'
      : status === Status.CONFIRMED
      ? 'accept'
      : '';
  }

  navigateToPayment() {
        this.studentSchedulesService.updateSelectedScheduleDetails(
      this.scheduleDetails
    );
    this.studentSchedulesService.updateAmount(this.scheduleDetails.amount);
    this.router.navigate(['/payment-page'],{queryParams:{sid:this.scheduleDetails._id}});
  }

  updateSchedule(status: string) {
    const payload = {
      student: this.scheduleDetails.student[0]._id,
      tutor: this.tutorDetails.userId,
      status: status,
      // slots : this.scheduleDetails.slots
    };
    this.action = status;
    if (status == 'CANCELLED') {
      this.message = 'Reason to Cancel Schedule';
      this.visible = true;
    }
    if (status == 'REJECTED') {
      this.message = 'Reason to Reject Schedule';
      this.visible = true;
    }
    if (status === 'REQUESTED') {
      this.requestSchedule();
    }
    if( status === this.statusList.COMPLETED){
      this.completeSchedule()
    }
  }

  completeSchedule(){
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: 'Are you sure want to mark this schedule as completed?' },
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result: any) => {
        if (result) {
          this.SchedulesService.completeSchedule(this.scheduleDetails._id).subscribe(
            (res) => {
              this.toastrService.success(res.message);
              this.getSchedulesDetailsByScheduleId();
            },
            (error: any) => {
              this.toastrService.error(
                error.error.message ??
                  'unable to update! Please try again later'
              );
            }
          )
        }
      }
    );
  }

  cancelOrRejectScheduleReason() {
    this.visible = false;
    const payload = {
      id: this.scheduleDetails._id,
      reason: this.reason,
    };
    if (this.action == 'CANCELLED') {
      this.SchedulesService.cancelSchedule(payload).subscribe();
      this.getSchedulesDetailsByScheduleId();
    }
    if (this.action == 'REJECTED') {
      this.SchedulesService.rejectSchedule(payload).subscribe();
      this.getSchedulesDetailsByScheduleId();
    }
    this.reason = '';
  }

  acceptSchedule(status: string) {
    const payload: { id: string; amount: number } = {
      id: this.scheduleDetails._id,
      amount: this.totalAmountTobePaid,
    };

    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: {
        confirmMessage: `Processed with Accepting the schedule with $${this.totalAmountTobePaid} ?`,
      },
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.SchedulesService.updateAcceptStatus(payload).subscribe(
            (res) => {
              this.toastrService.success(res.message);
              this.getSchedulesDetailsByScheduleId();
            },
            (error: any) => {
              this.toastrService.error(
                error.error.message ??
                  'unable to update! Please try again later'
              );
            }
          );
        }
      }
    );
  }

  navigateToBackPages(endpoint: string) {
    this.router.navigate([`/${this.logedinUserRole}/${endpoint}`]);
  }

  calculateTotalAmount() {
    let amount = 0;
    for (let slot = 0; slot < this.slots.length; slot++) {
      let time = this.slots[slot].to - this.slots[slot].from;
      amount = amount + (time / 2) * this.tutorDetails.hourlyRate;
    }
    this.totalAmountTobePaid = amount;
    if(this.scheduleDetails?.amount){
      this.discount = this.totalAmountTobePaid - this.scheduleDetails?.amount
    }
  }

  navigateToPayouts(endpoint: string) {
    this.router.navigate([`/${this.logedinUserRole}/${endpoint}`]);
  }

  editSchedule() {
    this.router.navigate(['./student/courses/schedule'], {
      queryParams: { id: this.tutorId, sid: this.scheduleDetails._id },
    });
  }

  requestSchedule() {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: {
        confirmMessage: `Are you sure to Request this schedule again`,
      },
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.SchedulesService.requestSchedule(
            this.scheduleDetails._id
          ).subscribe(
            (res) => {
              this.toastrService.success(res.message);
              this.getSchedulesDetailsByScheduleId();
            },
            (error: any) => {
              this.toastrService.error(
                error.error.message ??
                  'unable to update! Please try again later'
              );
            }
          );
        }
      }
    );
  }

  saveNotes() {
    this.SchedulesService.addNotes(this.scheduleDetails._id, {notes:this.notes}).subscribe((res:any)=>{
      this.getSchedulesDetailsByScheduleId()
      
    })
  }

  updatedFilesDescription(event: any) {
    this.fileAttachmments = event;
    this.uploadDocument();
  }

  uploadDocument() {
    //future use
    // for(let i=0; i<this.fileAttachmments.length; i++){
    //   let item =this.fileAttachmments[i].name
    //   this.studentProfileService.uploadFiletourl(this.fileAttachmments[i].name,this.fileAttachmments[i].type,"Schedule-attchemet",this.scheduleId ).subscribe((res:any)=>{
    //   if(res?.uploadUrl){
    //     // this.updateFileInS3(res.uploadUrl, this.fileObj.type,this.fileObj)
    //     const saveUrl= res.uploadUrl.split('?')
    //     this.attachments.push({attachment:saveUrl[0], name:this.fileAttachmments[i].name, type:this.fileAttachmments[i].type, size:this.fileAttachmments[i].size})
    //       this.studentProfileService.updateFileInS3(res.uploadUrl,this.fileAttachmments[i].type,this.fileAttachmments[i]).subscribe((res:any)=>{

    //       })
    //   }
    //  })
    // }

    let item = this.fileAttachmments[0].name;
    this.studentProfileService
      .uploadFiletourl(
        this.fileAttachmments[0].name,
        this.fileAttachmments[0].type,
        'Schedule-attchemet',
        this.scheduleId
      )
      .subscribe((res: any) => {
        if (res?.uploadUrl) {
          const saveUrl = res.uploadUrl.split('?');
          this.attachments=[{
            url: saveUrl[0],
            name: this.fileAttachmments[0].name,
            type: this.fileAttachmments[0].type,
            size: this.fileAttachmments[0].size,
          }];
          this.studentProfileService
            .updateFileInS3(
              res.uploadUrl,
              this.fileAttachmments[0].type,
              this.fileAttachmments[0]
            )
            .subscribe((res: any) => {
              this.saveAttachments()
            });
        }
      });
  }

  saveAttachments(){
    this.SchedulesService.addAttachments(this.scheduleDetails._id, this.attachments).subscribe((res:any)=>{
      this.clearAttachments=[]
      this.fileAttachmments=[]
      this.getSchedulesDetailsByScheduleId()
    })
  }

  naviagteToTutorRating(){
    this.router.navigate(['student/rate-tutor'],{queryParams:{id:this.tutorId}});
  }

  deleteAttachments(index: number){
    this.scheduleDetails.attachments.splice(index, 1);
    let payload = {attachments : this.scheduleDetails.attachments, student:this.scheduleDetails.student[0]._id, tutor: this.scheduleDetails.tutor[0].userId};
    this.SchedulesService.updateSchedule(this.scheduleDetails._id, payload).subscribe((res: any)=>{
      this.getSchedulesDetailsByScheduleId();
    })
  }
}
