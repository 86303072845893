import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { catchError, Observable, retry, throwError } from "rxjs";
import { STORAGE_KEYS } from "../enums/storage.enum";
// import { ConfirmationPopupService } from "../popup/confirmation-popup/confirmation-popup.service";
import { StorageService } from "../services/storage-service/storage-service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private storageService: StorageService,
        // private confirmationPopupService:ConfirmationPopupService,
        private toastrService: ToastrService,
        private router: Router
    ) { }

    handleError = (error: any) => {
        if (error.status === 401) {
            this.storageService.clearLocalStorage();
            this.toastrService.error('UnAuthrized Please login again');
            this.router.navigate(['/login-page']);
        }
        return throwError(error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.storageService.getDataFromLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);

        if (token && request.headers.get('Anonymous') !=='') {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`)
            })
        }

        return next.handle(request).pipe(
            // retry(2),
            catchError(this.handleError)
        )
    }

}
