import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { StorageService } from '../services/storage-service/storage-service';

@Directive({
  selector: '[appRole]'
})
export class RoleDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private storageService : StorageService
  ) { }

  @Input() set appRole(role : string){
    if(role.split(',')?.find((r)=>r === this.storageService.getDataFromLocalStorage('role'))){
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else{
      this.viewContainer.clear()
    }
  }

}
