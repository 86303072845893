import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZoneConverter'
})
export class TimeZoneConverterPipe implements PipeTransform {

  transform(utcDate: string, targetTimeZone: string): string {
    const date = new Date(utcDate);
    const options = {
      timeZone: targetTimeZone,
      hour: '2-digit' as const,
      minute: '2-digit' as const,
      hour12: false,
    } as Intl.DateTimeFormatOptions;
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

}
