<div (click)="activeModal.close(false)" class="close">
  <img src="../../../../../assets/img/cross-circle-svgrepo-com.svg" alt="cross icon" height="25" width="25">
</div>
<div class="content">
  <div class="modal-body top-aligned-modal">
    <div class="user-layout page-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-md-10 mx-auto pb-5">
            <div class="card">
              <div class="card-header bg-gradient-primary p-4 position-relative">
                <h3 class="text-white mb-0">Privacy Policy &amp; Terms of Service</h3>
                <!-- <p class="text-white opacity-8 mb-0">Last modified: Feb 01 2021</p>. -->
              </div>
              <div class="card-body p-4 pt-0" *ngIf="!scrollup" [innerHTML]="privacyPolicy">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
