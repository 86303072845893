import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PostLoginComponent } from './layout/post-login/post-login.component';
import { PreLoginComponent } from './layout/pre-login/pre-login.component';
import { LoginGuard } from './shared/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '',
    component: PreLoginComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./views/pre-login/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'login-page',
        loadChildren: () =>
          import('./views/pre-login/login-page/login-page.module').then(
            (m) => m.LoginPageModule
          ),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./views/pre-login/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path : 'courses/all',
        loadChildren  : () => import('./views/post-login/common/courses/courses.module').then((m)=>m.CoursesModule)
      },
      {
        path : 'common/contactUs',
        loadChildren  : () => import('./views/pre-login/contact-us/contact-us.module').then((m)=>m.ContactUsModule)
      },
      {
        path: 'courses/tutors',
        loadChildren: () =>
          import(
            './views/post-login/student/student-courses/student-courses.module'
          ).then((m) => m.StudentCoursesModule),
      },
      {
        path: 'tutor/tutor-details',
        loadChildren: () =>
          import(
            './views/post-login/student/tutor-details/tutor-details.module'
          ).then((m) => m.TutorDetailsModule),
      },
      {
        path: 'common/faq',
        loadChildren: () =>
          import('./views/post-login/admin/faq/faq.module').then(
            (m) => m.FaqModule
          ),
      },
    ],
  },
  {
    path: '',
    component: PostLoginComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'admin/dashboard',
        loadChildren: () =>
          import(
            './views/post-login/admin/admin-dashboard/admin-dashboard.module'
          ).then((m) => m.AdminDashboardModule),
      },
      {
        path: 'admin/category',
        loadChildren: () =>
          import('./views/post-login/admin/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: 'admin/course',
        loadChildren: () =>
          import('./views/post-login/admin/course/course.module').then(
            (m) => m.CourseModule
          ),
      },
      {
        path: 'admin/tutor-list',
        loadChildren: () =>
          import(
            './views/post-login/admin/tutors-list/tutors-list.module'
          ).then((m) => m.TutorsListModule),
      },
      {
        path: 'admin/tutor-list/user-details-page',
        loadChildren: () =>
          import(
            './views/post-login/admin/user-details-page/user-details-page.module'
          ).then((m) => m.UserDetailsPageModule),
      },
      {
        path: 'admin/student-list/user-details-page',
        loadChildren: () =>
          import(
            './views/post-login/admin/user-details-page/user-details-page.module'
          ).then((m) => m.UserDetailsPageModule),
      },
      {
        path: 'admin/student-list',
        loadChildren: () =>
          import(
            './views/post-login/admin/students-list/students-list.module'
          ).then((m) => m.StudentsListModule),
      },
      {
        path: 'tutor/dashboard',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-dashboard/tutor-dashboard.module'
          ).then((m) => m.TutorDashboardModule),
      },
      {
        path: 'tutor/profile',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-profile/tutor-profile.module'
          ).then((m) => m.TutorProfileModule),
      },
      {
        path: 'tutor/schedules',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-schedules/tutor-schedules.module'
          ).then((m) => m.TutorSchedulesModule),
      },
      {
        path: 'tutor/course',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-course/tutor-course.module'
          ).then((m) => m.TutorCourseModule),
      },
      {
        path: 'tutor/my-courses',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-course-list/tutor-course-list.module'
          ).then((m) => m.TutorCourseListModule),
      },
      {
        path: 'tutor/course-details',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-course-details/tutor-course-details.module'
          ).then((m) => m.TutorCourseDetailsModule),
      },
      {
        path: 'tutor/ratings',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-rating-list/tutor-rating-list.module'
          ).then((m) => m.TutorRatingListModule),
      },

      {
        path: 'student/ratings',
        loadChildren: () =>
          import(
            './views/post-login/tutor/tutor-rating-list/tutor-rating-list.module'
          ).then((m) => m.TutorRatingListModule),
      },
      {
        path: 'student/dashboard',
        loadChildren: () =>
          import(
            './views/post-login/student/student-dashboard/student-dashboard.module'
          ).then((m) => m.StudentDashboardModule),
      },
      {
        path: 'student/profile',
        loadChildren: () =>
          import(
            './views/post-login/student/student-profile/student-profile.module'
          ).then((m) => m.StudentProfileModule),
      },
      {
        path: 'student/schedules',
        loadChildren: () =>
          import(
            './views/post-login/student/student-schedules/student-schedules.module'
          ).then((m) => m.StudentSchedulesModule),
      },
      {
        path: 'student/courses',
        loadChildren: () =>
          import(
            './views/post-login/student/student-courses/student-courses.module'
          ).then((m) => m.StudentCoursesModule),
      },
      {
        path: 'student/batch-courses',
        loadChildren: () =>
          import(
            './views/post-login/student/batch-courses/batch-courses.module'
          ).then((m) => m.BatchCoursesModule),
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./views/post-login/common/messages/messages.module').then(
            (m) => m.MessagesModule
          ),
      },
      {
        path: 'student/tutor-details',
        loadChildren: () =>
          import(
            './views/post-login/student/tutor-details/tutor-details.module'
          ).then((m) => m.TutorDetailsModule),
      },
      {
        path: 'student/rate-tutor',
        loadChildren: () =>
          import(
            './views/post-login/student/rate-tutor/rate-tutor.module'
          ).then((m) => m.RateTutorModule),
      },

      {
        path: 'payment-page',
        loadChildren: () =>
          import(
            './views/post-login/common/payment-page/payment-page.module'
          ).then((m) => m.PaymentPageModule),
      },

      {
        path : 'admin/payout',
        loadChildren  : () => import('./views/post-login/admin/payouts/payouts.module').then((m)=>m.PayoutsModule)
      },
      {
        path : 'common/paymentList',
        loadChildren  : () => import('./views/post-login/common/payment-list/payment-list.module').then((m)=>m.PaymentListModule)
      },
      {
        path : 'common/courses',
        loadChildren  : () => import('./views/post-login/common/courses/courses.module').then((m)=>m.CoursesModule)
      },
      {
        path: 'admin/payout',
        loadChildren: () =>
          import('./views/post-login/admin/payouts/payouts.module').then(
            (m) => m.PayoutsModule
          ),
      },
      {
        path: 'admin/faq',
        loadChildren: () =>
          import('./views/post-login/admin/faq/faq.module').then(
            (m) => m.FaqModule
          ),
      },
      {
        path: 'admin/contact-us',
        loadChildren: () =>
          import('./views/post-login/admin/contact-us-listing/contact-us.module').then(
            (m) => m.ContactUsListingModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./views/post-login/admin/contact-us-listing/contact-us-routing.module').then(
            (m) => m.ContactUsRoutingModule
          ),
      },
      {
        path: 'notes',
        loadChildren: () =>
          import('./views/post-login/student/notes/notes.module').then((m) => m.NotesModule)
      },
      {
        path: 'admin/privacy-policies',
        loadChildren: () =>
          import('./views/post-login/admin/privacy-policies/privacy-policies.module').then((m) => m.PrivacyPoliciesModule)
      },
      {
        path: 'admin/aboutus',
        loadChildren: () =>
          import('./views/post-login/admin/aboutus/aboutus.module').then((m) => m.AboutusModule)
      },


    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
