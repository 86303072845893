import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NgSelect2Module } from 'ng-select2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PreLoginComponent } from './layout/pre-login/pre-login.component';
import { PostLoginComponent } from './layout/post-login/post-login.component';
import { HeaderComponent } from './shared/header/header.component';
import { NavBarComponent } from './shared/nav-bar/nav-bar.component';
import { HttpConfigInterceptor } from './shared/Interceptors/http-config.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmDialogModule } from './shared/confirm-dialog/confirm-dialog.module';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { SharedModule } from './shared/shared.module';
import { AdminHeaderComponent } from './shared/admin-header/admin-header.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScheduleDetailsComponent } from './views/post-login/common/schedule-details/schedule-details.component';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { TutorsListComponent } from './views/post-login/admin/tutors-list/tutors-list.component';
import { StudentsListComponent } from './views/post-login/admin/students-list/students-list.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { FileuploaderComponent } from './shared/components/fileuploader/fileuploader.component';
import { EditorModule } from 'primeng/editor';
import { SideBarComponent } from './shared/side-bar/side-bar.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    PreLoginComponent,
    PostLoginComponent,
    HeaderComponent,
    NavBarComponent,
    AdminHeaderComponent,
    ScheduleDetailsComponent,
    TutorsListComponent,
    StudentsListComponent,
    FileuploaderComponent,
    SideBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    ToastrModule.forRoot(),
    // HttpClientInMemoryWebApiModule.forRoot(DataService),
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    NgSelect2Module,
    ModalModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    SharedModule,
    ProgressSpinnerModule,
    ButtonModule,
    InputNumberModule,
    CheckboxModule,
    TableModule,DialogModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    TooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1015065036701-11ogt3j0et6o22ll67alg4t0uatvafip.apps.googleusercontent.com'
            ), // your client id
          },
        ],
      },
    },
    BsModalService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
