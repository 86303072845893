import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../admin-dashboard/dashboard.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tutors-list',
  templateUrl: './tutors-list.component.html',
  styleUrls: ['./tutors-list.component.css'],
})
export class TutorsListComponent implements OnInit {
  allTutors: any;
  modalRef!: BsModalRef;

  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private modalService: BsModalService,
    private tosterService: ToastrService
  ) {}

  ngOnInit(): void {
    this.getAllTutors();
  }

  getAllTutors() {
    this.dashboardService.getAllTutors().subscribe((res) => {
      this.allTutors = res.allTutors.reverse();
      this.getAllTutoringHours();
    });
  }
  

  getAllTutoringHours() {
    this.dashboardService.getTotalTutoringHours().subscribe((res) => {
      for (const tutorHour of res) {
        for (const tutor of this.allTutors) {
          if (tutorHour.tutorId === tutor._id) {
            tutor.hoursSpent = tutorHour.hoursSpent;
            break
          } 
        }
      }
    });
  }

  navigateToUserDetails(tutorId: any) {
    this.router.navigate(['admin/tutor-list/user-details-page'], {
      queryParams: { tId: tutorId },
    });
  }

  disableTutor(tutor: any, type: string) {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: `Are you sure want to ${type}?` },
      backdrop: 'static', // Prevents modal from closing on backdrop click
      keyboard: false  
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.dashboardService.deactivateUser(tutor._id).subscribe(
            (res) => {
              this.tosterService.success(res.message);
              this.getAllTutors();
            },
            (error) => {
              this.tosterService.error(error.message);
            }
          );
        }
      }
    );
  }

  activateTutor(tutor: any, type: string) {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: `Are you sure want to ${type}?` },
      backdrop: 'static', // Prevents modal from closing on backdrop click
      keyboard: false  
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.dashboardService
            .activateUser(tutor._id, { isActive: !tutor.isActive })
            .subscribe(
              (res) => {
                this.tosterService.success(res.message);
                this.getAllTutors();
              },
              (error) => {
                this.tosterService.error(error.message);
              }
            );
        }
      }
    );
  }
}
