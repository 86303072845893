import {
  Component,OnInit
} from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { HttpDataService } from './shared/services/http-data/http-data.service';
import { AttributeService } from './shared/services/storage-service/attribute.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Eduffirm';

  constructor(
    public Attrubuteservice: AttributeService,
    private Router: Router,
    public httpDataService:HttpDataService
  ) { }
  ngOnInit(){
    this.Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
      }
    });
    this.Attrubuteservice.usersideMenuresponsive();
  }

  isLoading = this.httpDataService.loadingCount === 0 ? false : true
}
