<div class="col-sm-6 chip-input-container">
    <div class="chip-input">
        <div class="chips">
            <span *ngFor="let chip of chips; let i = index" class="chip">
                {{ chip | titlecase }}
                <span class="delete-icon" (click)="removeChip(i)">
                    <i class="fa-regular fa-circle-xmark"></i>
                </span>{{ i !== chips.length - 1 ? '' :
                '' }}
            </span>
        </div>
        <input style="border: none;"
           type="text"
           [placeholder]="(chips || []).length > 0 ? '' : 'Add a chip or choose from suggestions'"
           [(ngModel)]="chipInput"
           (keydown)="filterSuggestions($event)" />
    </div>
    
</div>


<ul  class="col-sm-6 suggestions"
[class.active]="chipInput"
(mouseleave)="handleMouseLeave()" *ngIf="chipInput">
    <li *ngFor="let suggestion of filteredSuggestions; let i = index" 
        [class.active]="i === selectedIndex" 
        (click)="addSuggestion(suggestion)">
      <span [innerHTML]="highlightMatch(suggestion, chipInput)"></span>
    </li>
    <li *ngIf="filteredSuggestions.length === 0 && chipInput" (click)="addChip()">
      Add <strong>"{{ chipInput }}"</strong> course
    </li>
  </ul>
  