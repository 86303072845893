import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage-service/storage-service';
import { STORAGE_KEYS } from '../enums/storage.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrivacyComponent } from 'src/app/views/pre-login/home/privacy/privacy.component';
import { CompanyDetailsService } from '../services/company-details.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  role:undefined;
  islogin : boolean = false;
  logedinRole: string | undefined;
  page='';
  privacyPolicy: string = '';

  constructor(
    private router: Router,
    private storageService: StorageService,
    private modalService: NgbModal,
    private companyDetailsService: CompanyDetailsService
  ) { }

  ngOnInit(): void {
    this.role= this.storageService.getDataFromLocalStorage(STORAGE_KEYS.ROLE);
    let role = this.storageService.getDataFromLocalStorage('role');
    if (role) {
      this.logedinRole = role;
      this.islogin = true;
    }
    this.getPrivacyPolicy();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
});

  }

  navigateToTutorRegistration(){
    this.router.navigate(['/register'],{queryParams : {as: 'tutor'}});
  }

  navigateToStudentRegistration(){
    this.router.navigate(['/register'],{queryParams : {as: 'student'}});
  }


  openPrivacyPolicyModal() {
    const dialogRef = this.modalService.open(PrivacyComponent,  { centered: true, size: 'lg' });
      // data: { privacyPolicy: this.PrivacyComponent }
      dialogRef.componentInstance.privacyPolicy = this.privacyPolicy;

  }

  getPrivacyPolicy(){
    this.companyDetailsService.getPrivacyPolicy().subscribe((res: any)=>{
        this.privacyPolicy = res.privacyPolicy[0].content
     })
  }
}
