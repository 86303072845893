import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage-service/storage-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TutorProfileService } from 'src/app/views/post-login/tutor/tutor-profile/tutor-profile.service';
import { STORAGE_KEYS } from '../enums/storage.enum';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent implements OnInit {
  modalRef!: BsModalRef;
  isLoginShow: boolean = true;
  userType!: string;
  userDetails: any;
  page = 'dashboard';
  isMiniSidenav: boolean = false;
  alertList: any = [];
  userId:string=''
  notificationPanel:number= 0;
  unreadCount:number=0;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private modalService: BsModalService,
    private tutorProfileService: TutorProfileService,
    private alertService: AlertService
  ) {
    this.userId = this.storageService.getDataFromLocalStorage(STORAGE_KEYS.USER_ID);
  }
  

  ngOnInit(): void {
    this. getUserDeatails();
    this.getUserNotification();
  }

  getUserDeatails() {
    this.tutorProfileService.getUserDetails(this.storageService.getDataFromLocalStorage('id')).subscribe((res: any) => {
      this.userDetails = res.existingUser;
    })
  }

  getUserNotification() {
    if(this.userId){
      this.alertService.getUserNotification(this.userId).subscribe((res: any) => {
        console.log(res.alerts.alerts)
        this.alertList = res.alerts.alerts.reverse();
        this.unreadCount  = res.alerts.unreadCount;
      });
    }
  }

  deleteAllNotifications() {
    this.alertService
      .deleteNotifications(this.userId)
      .subscribe((res: any) => {
        this.getUserNotification()
      });
  }

  deleteSelectedNotification(alertId:string){
    this.alertService.deleteSelectedNotification(alertId).subscribe(res=>{
      this.getUserNotification()
    })
  }

  navigateToAlertDetails(alertDetails: any) {
    this.deleteSelectedNotification(alertDetails._id)
    if (alertDetails.type === 'Schedule') {
      this.router.navigate([`/${this.userDetails?.roles[0] === 'Student' ? 'student':'tutor'}/schedules/details`], {
        queryParams: { sid: alertDetails.data.scheduleId },
      });
    } 
  }

  showNotificationPanel(id:number){
    if(id === this.notificationPanel){
      this.notificationPanel = 0
    }else{
      this.notificationPanel = id;
    }
  }

  navigateToHomepage(){
    if(this.userDetails?.roles[0] === 'Tutor'){
      return;
    }
    this.router.navigate(['home']);
  }

  onLogout() {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: 'Are you sure want to logout?' },
      backdrop: 'static', // Prevents modal from closing on backdrop click
      keyboard: false  

    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result: any) => {
        if (result) {
          this.storageService.clearLocalStorage();
          this.router.navigate(['/home']);
          this.isLoginShow = true;
        }
      }
    );
  }

  change(name: any) {
    this.page = name;
  }
}
