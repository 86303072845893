import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpDataService } from 'src/app/shared/services/http-data/http-data.service';

@Injectable({
  providedIn: 'root'
})
export class TutorProfileService {

  constructor(
    public httpDataService:HttpDataService
  ) { }

  getProfileByUserId(userId : string):Observable<any>{
    return this.httpDataService.get(`tutorProfile/userId/${userId}`)
  }

  getUserDetails(userId : string):Observable<any>{
    return this.httpDataService.get(`user/${userId}`)
  }

  postTutorProfile(payload : any):Observable<any>{
    return this.httpDataService.post(`tutorProfile`,payload)
  }

  getAllTutorPayouts(payload : any):Observable<any>{
    return this.httpDataService.post(`payments/getPayoutsCriteria`,payload)
  }

  getAllCourses():Observable<any>{
    return this.httpDataService.get(`course`);
  }

  getAllReviews(tutorId:string):Observable<any>{
    return this.httpDataService.get(`ratings/${tutorId}`);
  }

  updateProfileData(payload : any, tutorId : string) : Observable<any> {
    return this.httpDataService.put(`tutorProfile/${tutorId}`,payload);
  }

  updateTutorSlots(userId:string,payload:any):Observable<any>{
    return this.httpDataService.put(`tutorProfile/slots/${userId}`,payload);
  }

  updateTutorBankDetails(profileId:string,payload:any):Observable<any>{
    return this.httpDataService.put(`tutorProfile/bankAccount/${profileId}`,payload);
  }

  postEductionDetails(tutorProfileId:any, postEducationalDetails: any):Observable<any>{
    return this.httpDataService.put(`tutorProfile/educationQualification/${tutorProfileId}`,postEducationalDetails)
  }

  getTutorProfileScore(userId : string):Observable<any>{
    return this.httpDataService.get(`tutorProfile/profileScore/${userId}`)
  }
 
}


