import { Component, OnInit } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { TutorProfileService } from 'src/app/views/post-login/tutor/tutor-profile/tutor-profile.service';
import { StorageService } from '../services/storage-service/storage-service';
import { STORAGE_KEYS } from '../enums/storage.enum';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  public bellCollapsed = true;
  public userCollapsed = true;
  base = '';
  page = '';
  splitVal:any;
  userDetails : any;
  role:string = '';
  isAdmin: boolean = false;

 constructor(
    public router: Router,
    private storageService:StorageService,
    private tutorProfileService : TutorProfileService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.splitVal = event.url.split('/');
        this.base = this.splitVal[1];
        this.page = this.splitVal[2];
      }
    });
  }
  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
      $("html").removeClass("menu-opened");
      $(".sidebar-overlay").removeClass("opened");
      $(".main-wrapper").removeClass("slide-nav");
      }
      });
      this.role = this.storageService.getDataFromLocalStorage('role');
      if(this.role){
        if(this.role.toLocaleLowerCase() === 'admin'){
          this.isAdmin = true
        }

        if(this.role.toLocaleLowerCase() === 'tutor'){
          this.getProfileDetails()
        }
        this.getUserDeatails();
      }
  }

  getUserDeatails(){
    this.tutorProfileService.getUserDetails(this.storageService.getDataFromLocalStorage('id')).subscribe((res)=>{
      this.userDetails = res.existingUser;
      if(this.userDetails.timeZone){
        this.storageService.setDataToLocalStorage(STORAGE_KEYS.TIME_ZONE, this.userDetails.timeZone);
      }
    })
  }

  getProfileDetails(){
    this.tutorProfileService.getProfileByUserId(this.storageService.getDataFromLocalStorage('id')).subscribe((res: any) => {
      console.log(res);
      if(res?.data?.timeZone){
        this.storageService.setDataToLocalStorage(STORAGE_KEYS.TIME_ZONE, res.data.timeZone);
      }
    })
  }

  ngAfterViewInit() {
    this.loadDynmicallyScript('assets/admin/js/script.js');
  }
  loadDynmicallyScript(js:any) {
    var script = document.createElement('script');
    script.src = js;
    script.async = false;
    document.head.appendChild(script);
    script.onload = () => this.doSomethingWhenScriptIsLoaded();
  }

  doSomethingWhenScriptIsLoaded() {}
  change(name:any) {
    this.page = name;
  }

  clickLogout() {
    window.location.href = '/index';
  }
  bell() {
    this.bellCollapsed = !this.bellCollapsed;
    if (!this.userCollapsed) {
      this.userCollapsed = true;
    }
  }
  user() {
    this.userCollapsed = !this.userCollapsed;
    if (!this.bellCollapsed) {
      this.bellCollapsed = true;
    }
  }
  
  onLogout(){
    this.storageService.clearLocalStorage();
    this.router.navigate(['/home']);
  }

}
