<!-- <loader></loader> -->
<div class="main-wrapper">
    <div class="progress-spinner" *ngIf="httpDataService.loadingCount !== 0">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <router-outlet></router-outlet>
    <!-- <df-messenger 
        intent="WELCOME" 
        chat-title="Eduffirm-Support" 
        agent-id="3d0ff476-3339-4709-bb4c-761042b1d88f"
        language-code="en">
    </df-messenger> -->
</div>