<div class="file-upload_container" appDnd (fileDropped)="onFileDropped($event)">
	<input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event)" 
	accept="application/pdf,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword application/vnd.ms-powerpoint" />
	
  <!-- <i class="fa fa-upload" aria-hidden="true"></i>
	<h3>Drag and drop file here</h3>
	<h4>or</h4>
	<label for="fileDropRef">Browse for file</label> -->
	<img src="./../../../../assets/Addfile-icon.png" alt="">
	<div>+ Add</div>
</div>

<!-- <div class="files-list" *ngIf="files.length>0">
    <div class="single-file">
		<div class="file-icon" style="width: 50px">
            <i class="fa fa-file" aria-hidden="true"></i>
		</div>
		<div class="info">
			<h4 class="name">
				{{ files[0]?.name }}
			</h4>
			<p class="size">
				{{ formatBytes(files[0]?.size) }}
			</p>
		</div>

		<div class="delete" (click)="clearFile()">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </div>
</div> -->
<!-- <div class="files-list">
	<div class="single-file " *ngFor="let file of files; let i = index">
		<div class="file-icon" style="width: 50px">
            <i class="fa fa-file" aria-hidden="true"></i>
		</div>
		<div class="info">
			<h4 class="name">
				{{ file?.name }}
			</h4>
			<p class="size">
				{{ formatBytes(file?.size) }}
			</p>
		</div>

		<div class="delete" (click)="deleteFile(i)">
      <i class="fa fa-trash" aria-hidden="true"></i>
		
	</div>
</div> -->
