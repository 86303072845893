import { HttpDataService } from './../http-data/http-data.service';
import { Injectable } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {


  constructor( private socialAuthService: SocialAuthService,
    private httpDataService : HttpDataService
  ) {

  }

  initiateGoogleAuthentication():Observable<any>{
    return this.socialAuthService.authState;
    // return this.socialAuthService.initState;
  }
  signinWithGoogle():Promise<any>{
    return this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
  }

  logoutWithGoogleApi(loginDetails: any): Observable<any> {
    return this.httpDataService.authPost(`auth/googleSignOut`, loginDetails);
  }

  signOutGoogle(): Promise<void> {
    return this.socialAuthService.signOut();
  }

}
