<div class="page-header">
  <div class="row">
    <div class="col-md-11">
      <div class="col">
        <h3 class="page-title">Tutor List</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/tutor/dashboard'">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Tutor list</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div *ngIf="allTutors?.length!=0">
              <p-table [value]="allTutors"  [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10, 25, 50]">
                <!-- <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="columns?.length">No tutor found</td>
                </tr>
              </ng-template> -->

                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      Name 
                    </th>
                    <th>
                      Phone Number 
                    </th>
                    <th>
                      Email 
                    </th>
                    <th>
                      Hours Spent 
                    </th>
                    <th>
                      Action 
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-tutor>
                  <tr>
                    <td>
                      <span class="Userimg_wrapper">
                        <div  *ngIf="tutor?.photoUrl">
                          <img [src]="tutor?.photoUrl" class="User_Image" alt="User Image">
                        </div>
                        <div *ngIf="!tutor?.photoUrl">
                          <div class="User-initial">
                            {{ tutor?.firstName?.split('')[0] | uppercase}}{{ tutor?.lastName?.split('')[0] | uppercase}}
                          </div>
                        </div>
                        <span>{{ tutor?.firstName }} {{ tutor?.lastName }}</span>
                      </span>
                    </td>
                    <td>{{ tutor.phone }}</td>
                    <td>{{ tutor.email }}</td>
                    <td>{{ tutor.hoursSpent ? tutor.hoursSpent : 0}}</td>
                    <td>
                      <button class="btn btn-sm btn-primary" (click)="navigateToUserDetails(tutor._id)">
                        <i class="far fa-eye"></i> View
                      </button>
                      <button *ngIf="tutor.status == 'deActive'" data-bs-toggle="tooltip" data-bs-title="Disable"
                        class="btn btn-sm btn-danger m-1" (click)="disableTutor(tutor, 'Deactivate')">
                        <i class="fa fa-ban"></i>
                      </button>
                      <button *ngIf="tutor.status == 'active'" class="btn btn-sm btn-outline-primary m-1"
                        (click)="activateTutor(tutor, 'Activate')" data-bs-toggle="tooltip" data-bs-title="Active">
                        <i class="fas fa-check-circle"></i>
                      </button>
                      <button *ngIf="tutor.status == 'pending'" class="btn btn-sm btn-outline-primary m-1"
                      (click)="activateTutor(tutor, 'Activate')" data-bs-toggle="tooltip" data-bs-title="Active">
                      <i class="fas fa-clock" style="color: orange;"></i>
                    </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div *ngIf="allTutors?.length === 0" class="card">
              <div class="card-body" style="text-align: center">
                <h5 class="text-danger">No tutors found</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>