<!-- Footer -->
<footer class="footer">

    <!-- Footer Top -->
    <div class="footer-top">
        <div>
            <div class="row">
                <div class="col-md-3">

                    <!-- Footer Widget -->
                    <div class="footer-widget footer-about">
                        <h2 class="footer-title"><img src="./../../../assets/Footer-logo.svg" alt=""></h2>
                        <div class="footer-about-content">
                            <p>Unleash Your Potential, Ignite Your Future.
                            </p>
                        </div>
                    </div>
                    <!-- /Footer Widget -->

                </div>
                <div class="col-md-9 row">
                    <div class="col-lg-3 col-md-6" *ngIf="!islogin">

                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu" >
                            <h2 class="footer-title">ONLINE TUTORING</h2>
                            <ul>
                                <li [class.active]="page === 'enrollastudent'"><a (click)="navigateToStudentRegistration()">Enroll As a Student</a></li>
                                <li [class.active]="page === 'becameatutor'"><a (click)="navigateToTutorRegistration()">Become A Tutor</a></li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->

                    </div>
                    <div class="col-lg-3 col-md-6">

                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">ORGANISATION</h2>
                            <ul>
                                <li><a [routerLink]="'/home/about-us'">About Us</a></li>
                                <!-- <li><a [routerLink]="'/message'">Who We Serve</a></li> -->
                                <li class="terms" (click)="openPrivacyPolicyModal()">Terms</li>
                            </ul>
                        </div>
                        <!-- /Footer Widget -->

                    </div>
                    <!-- <div class="col-lg-3 col-md-6">


                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Social</h2>
                            <ul>
                                <li><a [routerLink]="'/mentor/my-bookings'">instagram</a></li>
                                <li><a [routerLink]="'/message'">Twitter</a></li>

                            </ul>
                        </div>


                    </div> -->

                    <div class="col-lg-3 col-md-6">

                        <!-- Footer Widget -->
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Help</h2>
                            <ul>
                                <li><a [routerLink]="'/common/faq'">FAQs</a></li>
                                <li><a [routerLink]="'/common/contactUs'">Contact Us</a></li>


                            </ul>
                        </div>
                        <!-- /Footer Widget -->

                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">

        <!-- Copyright -->
        <div class="copyright">

            <div class="copyright-text">
                <!-- <p>Terms - Privacy Policy & Safety - Send feedback</p> -->
                <li class="terms" (click)="openPrivacyPolicyModal()">Terms - Privacy Policy & Safety - Send feedback</li>
            </div>

            <div class="copyright-text">
                <a href="#">all copyright (c) 2024 reserved</a>
            </div>

        </div>
        <!-- /Copyright -->
    </div>
    <!-- /Footer Bottom -->

</footer>
<!-- /Footer -->