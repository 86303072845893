<div class="page-header">
  <div class="row">
    <div class="col-md-11">
      <div class="col">
        <h3 class="page-title">Student List</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/tutor/dashboard'">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">Student List</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div *ngIf="allStudents !=0">
            <p-table [value]="allStudents" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [rowsPerPageOptions]="[10, 25, 50]">
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="firstName" >
                    Name 
                  </th>
                  <th pSortableColumn="phone">
                    Phone Number 
                  </th>
                  <th pSortableColumn="email" >
                    Email 
                  </th>
                  <th pSortableColumn="action">
                    Action 
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-student>
                <tr>
                  <td>
                    <span class="Userimg_wrapper ">
                      <div *ngIf="student?.photoUrl">
                        <img [src]="student?.photoUrl" class="User_Image" alt="User Image">
                      </div>
                      <div *ngIf="!student?.photoUrl">
                        <div class="User-initial">
                          {{ student?.firstName?.split('')[0] | uppercase}}{{ student?.lastName?.split('')[0] | uppercase}}
                        </div>
                      </div>
                      <span>
                        {{ student.firstName }} {{ student.lastName }}
                      </span>
                    </span>
                  
                  </td>
                  <td>{{ student.phone }}</td>
                  <td>{{ student.email }}</td>
                  <td>
                    <button class="btn btn-sm btn-primary" (click)="navigateToUserDetails(student._id)">
                      <i class="far fa-eye"></i> View 
                    </button>
                    <button *ngIf="student.status === 'active'" data-bs-toggle="tooltip" data-bs-title="Disable"
                        class="btn btn-sm btn-danger m-1" (click)="disableStudent(student, 'Deactivate')">
                        <i class="fa fa-ban"></i>
                      </button>
                      <button *ngIf="student.status === 'deActive'" 
                        class="btn btn-sm btn-outline-primary m-1" (click)="activateStudent(student, 'Activate')" data-bs-toggle="tooltip" data-bs-title="Active">
                        <i class="fas fa-check-circle"></i>
                      </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <div *ngIf="allStudents.length === 0" class="card">
              <div class="card-body" style="text-align: center">
                <h5 class="text-danger">No students found</h5>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>