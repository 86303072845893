import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  public onClose!: Subject<boolean>;
  public confirmMessage !: string;

  constructor(private modalRef: BsModalRef) {
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }
  public onConfirm(): void {
    this.onClose.next(true);
    this.modalRef.hide();
  }
  public onCancel() {
    this.onClose.next(false);
    this.modalRef.hide();
  }

}
