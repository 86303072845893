import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../admin-dashboard/dashboard.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.css'],
})
export class StudentsListComponent implements OnInit {
  allStudents: any;
  modalRef!: BsModalRef;

  constructor(private dashboardService: DashboardService,
    private router: Router,
    private modalService: BsModalService,
    private tosterService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getAllStudents();
  }

  getAllStudents() {
    this.dashboardService.getAllStudents().subscribe((res) => {
      this.allStudents = res.allStudents.reverse();
    });
  }

  navigateToUserDetails(studentId: any) {
    this.router.navigate(['admin/student-list/user-details-page'], { queryParams: { sId: studentId } });
  }


  activateStudent(student: any, type: string) {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: `Are you sure want to ${type}?` },
      backdrop: 'static', // Prevents modal from closing on backdrop click
      keyboard: false 
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.dashboardService.activateUser(student._id, { isActive: !student.isActive }).subscribe(
            (res) => {
              this.tosterService.success(res.message);
              this.getAllStudents();
            },
            (error) => {
              this.tosterService.error(error.message);
            }
          );
        }
      }
    );
  }


  disableStudent(student: any, type: string) {
    this.modalRef = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-dialog modal-dialog-centered',
      initialState: { confirmMessage: `Are you sure want to ${type}?` },
      backdrop: 'static', // Prevents modal from closing on backdrop click
      keyboard: false  
    });

    (<ConfirmDialogComponent>this.modalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.dashboardService.deactivateUser(student._id).subscribe(
            (res) => {
              this.tosterService.success(res.message);
              this.getAllStudents();
            },
            (error) => {
              this.tosterService.error(error.message);
            }
          );
        }
      }
    );
  }
}
