<!-- Header -->
<div class="header">
  <!-- Logo -->
  <div class="header-left">
    <a [routerLink]="'/admin/dashboard'" class="navbar-brand logo">
      <img src="./../../../assets/Adminsidenav-logo.svg">
    </a>
  </div>
  <!-- /Logo -->

  <!-- <a href="javascript:void(0);" id="toggle_btn">
    <i class="fa fa-bars"></i>
  </a> -->
  <!-- <div class="top-nav-search">
    <form>
      <input type="text" class="form-control" placeholder="Search here" />
      <button class="btn" type="submit">
        <i class="feather icon-search"></i>
      </button>
    </form>
  </div> -->

  <!-- Mobile Menu Toggle -->
  <a class="mobile_btn" id="mobile_btn_admin"> <i class="fa fa-bars"></i> </a>
  <!-- /Mobile Menu Toggle -->

  <!-- Header Right Menu -->
  <ul class="nav user-menu">
    <!-- Fullscreen -->
    <li class="nav-item dropdown">
      <a href="javascript:void(0)" id="btnFullscreen" class=" ">
        <i class="feather icon-maximize"></i>
      </a>
    </li>
    <!-- /Fullscreen -->

    <!-- Notifications -->
    <!-- <li class="nav-item dropdown noti-dropdown">
      <a href="javascript:void(0)" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
        <i class="feather icon-bell"></i>
        <span class="badge rounded-pill">3</span>
      </a> 
      <div class="dropdown-menu notifications">
        <div class="topnav-dropdown-header">
          <span class="notification-title">Notifications</span>
          <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
        </div>
        <div class="noti-content">
          <ul class="notification-list">
            <li class="notification-message">
              <a href="javascript:void(0)">
                <div class="media d-flex">
                  <span class="avatar avatar-sm flex-shrink-0">
                    <img class="avatar-img rounded-circle" alt="User Image" src="assets/admin/img/user/user.jpg" />
                  </span>
                  <div class="media-body flex-grow-1">
                    <p class="noti-details">
                      <span class="noti-title">Jonathan Doe</span> Schedule
                      <span class="noti-title">his appointment</span>
                    </p>
                    <p class="noti-time">
                      <span class="notification-time">4 mins ago</span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li class="notification-message">
              <a href="javascript:void(0)">
                <div class="media d-flex">
                  <span class="avatar avatar-sm flex-shrink-0">
                    <img class="avatar-img rounded-circle" alt="User Image" src="assets/admin/img/user/user1.jpg" />
                  </span>
                  <div class="media-body flex-grow-1">
                    <p class="noti-details">
                      <span class="noti-title">Julie Pennington</span> has
                      booked her appointment to
                      <span class="noti-title">Ruby Perrin</span>
                    </p>
                    <p class="noti-time">
                      <span class="notification-time">6 mins ago</span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li class="notification-message">
              <a href="javascript:void(0)">
                <div class="media d-flex">
                  <span class="avatar avatar-sm flex-shrink-0">
                    <img class="avatar-img rounded-circle" alt="User Image" src="assets/admin/img/user/user2.jpg" />
                  </span>
                  <div class="media-body flex-grow-1">
                    <p class="noti-details">
                      <span class="noti-title">Tyrone Roberts</span> sent a
                      amount of $210 for his
                      <span class="noti-title">appointment</span>
                    </p>
                    <p class="noti-time">
                      <span class="notification-time">8 mins ago</span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li class="notification-message">
              <a href="javascript:void(0)">
                <div class="media d-flex">
                  <span class="avatar avatar-sm flex-shrink-0">
                    <img class="avatar-img rounded-circle" alt="User Image" src="assets/admin/img/user/user4.jpg" />
                  </span>
                  <div class="media-body flex-grow-1">
                    <p class="noti-details">
                      <span class="noti-title">Patricia Manzi</span> send a
                      message <span class="noti-title"> to his Mentee</span>
                    </p>
                    <p class="noti-time">
                      <span class="notification-time">12 mins ago</span>
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="topnav-dropdown-footer">
          <a href="javascript:void(0)">View all Notifications</a>
        </div>
      </div>
    </li> -->
    <!-- /Notifications -->

    <li class="nav-item dropdown has-arrow main-drop">
      <a href="javascript:void(0)" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
        <span class="user-img"><img src="assets/admin/img/profiles/avatar-06.jpg" alt="" />
          {{ userDetails?.firstName | titlecase }}
          <span class="status online"></span></span>
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item" [routerLink]="
            userDetails?.roles[0] === 'Tutor'
              ? 'tutor/profile'
              : 'student/profile'
          "><i class="feather icon-user"></i> My Profile</a>
        <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()"><i class="feather icon-power"></i>
          Logout</a>
      </div>
    </li>
  </ul>
  <!-- /Header Right Menu -->
</div>
<!-- /Header -->

<!-- Sidebar -->
<div class="sidebar" id="sidebar">
  <div class="sidebar-inner slimscroll">
    <div id="sidebar-menu" class="sidebar-menu">
      <ul>
        <li [class.active]="page === 'dashboard'">
          <a (click)="change('dashboard')" [routerLink]="'admin/dashboard'"><i
              class="fa fa-home"></i><span>Dashboard</span></a>
        </li>
        <li [class.active]="page === 'categories'">
          <a (click)="change('categories')" [routerLink]="'admin/category'"><i
              class="fa fa-chevron-circle-down"></i><span>Categories</span></a>
        </li>
        <li [class.active]="page === 'courses'">
          <a (click)="change('courses')" [routerLink]="'admin/course'"><i
              class="fas fa-book"></i><span>Courses</span></a>
        </li>
        <li [class.active]="page === 'payout'">
          <a (click)="change('payout')" [routerLink]="'admin/payout'"><i
              class="fas fa-money-check-alt"></i><span>Payouts</span></a>
        </li>
        <li [class.active]="page === 'tutor-list'">
          <a (click)="change('tutor-list')" [routerLink]="'admin/tutor-list'"><i
              class="fas fa-chalkboard-teacher"></i><span>Tutor List</span></a>
        </li>
        <li [class.active]="page === 'student-list'">
          <a (click)="change('student-list')" [routerLink]="'admin/student-list'"><i
              class="fas fa-users"></i><span>Student List</span></a>
        </li>
        <li [class.active]="page === 'admin-faq'">
          <a (click)="change('admin-faq')" [routerLink]="'admin/faq'"><i
              class="fas fa-question-circle"></i><span>FAQ</span></a>
        </li>
        <li [class.active]="page === 'contact-us'">
          <a (click)="change('contact-us')" [routerLink]="'admin/contact-us'"><i class="fas fa-info-circle"></i>
            <span>Enquiries</span></a>
        </li>

        <li [class.active]="page === 'privacy-policies'">
          <a (click)="change('privacy-policies')" [routerLink]="'admin/privacy-policies'"><i class="fas fa-user-secret"></i>
            <span style="cursor: pointer;">Privacy Policies</span></a>
        </li>
        <li [class.active]="page === 'terms-and-conditions'">
          <a (click)="change('terms-and-conditions')" [routerLink]="'admin/aboutus'"><i
              class="fas fa-users"></i><span>About Us</span></a>
        </li>
        <li [class.active]="page === 'logout'">
          <a (click)="onLogout()" style="cursor: pointer;"><i class="fas fa-sign-out-alt"></i> <span>Logout</span></a>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- /Sidebar -->