<h3 class="page-title">Schedule Details</h3>

<div class="card mb-2">
  <div class="content mx-2">
    <div>
      <div class="">
        <div class="card-body">
          <div class="mentor-widget justify-content-between ">


            <div class="user-info-cont" *ngIf="displayDetails">
              <div class="usr_details">

                <div class="user-img" *ngIf="userProfilePhoto">
                  <img [src]="userProfilePhoto" class="User_Image" alt="User Image">
                </div>
                <div class="pro-avatar" *ngIf="!userProfilePhoto">
                  {{
                  sharedService.getNameInitials(
                  displayDetails?.firstName,
                  displayDetails?.lastName
                  )
                  }}
                </div>
                <!-- <div class="rating text-center">
                                            <bar-rating [(rate)]="rate" [max]="5"></bar-rating>
                                        </div> -->

                <h4 class="usr-name">
                  {{ displayDetails?.firstName }} {{ displayDetails.lastName }}
                </h4>
                
                <!-- <div class="hire-rate mt-2">Hourly fees <span>${{ tutorDetails?.hourlyRate }}</span> </div> -->
              </div>
             <div>
              <p class="mentor-type">
                {{ displayDetails.email }}
              </p>
              <p class="mentor-type" *ngIf="logedinUserRole === 'student'">
                {{ tutorDetails.title }}
              </p>
              <!-- <p class="mentor-type" *ngIf="logedinUserRole === 'student'">
                  {{ tutorDetails.description }}
                </p> -->
             
             </div>
              <!-- <div class="mentor-action" *ngIf="isTutor || logedinUserRole === 'admin'"> -->
                <!-- <p class="mentor-type social-title">Contact Address</p> -->
                <!-- <div class="mentor-details m-0">
                  <p class="user-location m-0">
                    <i class="fas fa-map-marker-alt"></i>
                    {{ studentDetails?.address}} , {{studentDetails?.addressLine2}}, {{studentDetails?.city}}, {{studentDetails?.state}}, {{studentDetails?.country}}, {{studentDetails?.zip}}
                  </p>
                </div>
              </div> -->

              <div class="btn_Align">
                <div *ngIf="scheduleDetails" [class]="statusClass(scheduleDetails?.status)">
                  <h3 class="m-0" >
                    {{ scheduleDetails?.status }}
                  </h3>
                  <p class="mentor-type" *ngIf="
                            scheduleDetails?.status === 'CANCELLED' ||
                            scheduleDetails?.status === 'REJECTED'
                          ">
                    {{ scheduleDetails?.rejectedNote }}
                  </p>
                </div>
                <div  *ngIf="!isTutor && !isAdmin && scheduleDetails.status === statusList.COMPLETED"><button
                    (click)="naviagteToTutorRating()" class="btn btn-primary w-100">Write a Review</button></div>
                <div  *ngIf="statusList.REQUESTED === scheduleDetails?.status && !isTutor && !isAdmin">
                  <button (click)="editSchedule()" class="btn btn-primary w-100">Edit Schedule</button>
                </div>
              </div>
              <!-- <div class="d-flex align-items-center gap-1">
                    <h4 class="m-0">Total Fee </h4>
                    <h4 class="total-amount m-0">$ {{ scheduleDetails?.amount ? scheduleDetails?.amount :
                      totalAmountTobePaid }}</h4>
                  </div> -->



            </div>

            <!-- <div class="user-info-right d-flex align-items-end flex-wrap" *ngIf="isTutor && scheduleDetails">
              <h3 [class]="statusClass(scheduleDetails?.status)">
                {{ scheduleDetails?.status }}
              </h3>
            </div> -->

            <div class="payment_summary">

              <h3 class="m-0">Fees</h3>
              <div class="summary">
                <div class="summary_align">
                  <h4>Schedule price</h4>
                  <span>{{totalAmountTobePaid}}$</span>
                </div>
                <div class="summary_align">
                  <h4>Discount</h4>
                  <p>{{discount}}$</p>
                </div>
                <div class="summary_align">
                  <h3>Total amount</h3>
                  <div class="total_amount">{{ scheduleDetails?.amount ? scheduleDetails?.amount :
                    totalAmountTobePaid }}$</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="">

        <div class="profile-menu">
          <ul class="nav nav-tabs nav-tabs-solid">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#about" id="">About</a>
            </li>
            <li class="nav-item" *ngIf="!isTutor && !isAdmin">
              <a class="nav-link" data-bs-toggle="tab" data-bs-target="#notes">Notes</a>
            </li>
            <li class="nav-item" *ngIf="!isAdmin">
              <a class="nav-link" data-bs-toggle="tab" data-bs-target="#pass">Attachments</a>
            </li>
          </ul>
        </div>
        <div class="tab-content profile-tab-cont">
          <div class="tab-pane fade show active" id="about">
            <!-- Personal Details -->
            <div class="row">
              <div class="col-lg-12">
                <div class="">
                  <div class="row">
                    <div class="mb-2 col-12 col-md-3">
                      <div class="card-body custom-about about_align-cards">
                        <div class="widget about-widget  mb-0" *ngIf="scheduleDetails">
                          <div class="">
                            <h4 class="widget-title">Courses</h4>

                            <div class="wrapper">
                              <div class="" >
                                <div class="cources_pills">
                                  <h3 *ngFor="
                                  let course of scheduleDetails.subjects;
                                  let index = index;
                                  let last = last
                                ">{{ course?.courseName }}</h3>
                                  <!-- <hr />

                                  <p class="mb-2">{{ course?.courseDiscription }}</p> -->
                                </div>
                              </div>

                            </div>
                           
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="card-body pb-0 about_align-cards">

                        <h4 class="widget-title title_heading">Slot Details</h4>

                        <div class="slots_wrapper" *ngIf="scheduleDetails">
                          <div class="accept m-1 p-2" *ngFor="let slot of scheduleDetails.slots">
                            <div>
                              <h5>{{ slot.date | date : "MMM d, y" }}</h5>
                              <p *ngIf="!slot.utcFromtime || !slot.utcTotime">{{timeValues[slot.from]}} -
                                {{timeValues[slot.to]}}</p>
                              <p *ngIf="slot.utcFromtime && slot.utcTotime">{{slot.utcFromtime | timeZoneConverter:
                                loggedinUserTimeZone }} - {{slot.utcTotime | timeZoneConverter: loggedinUserTimeZone
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      <div class="card-body">
                        <div class="btn_group mt-2" *ngIf="!isAdmin">
                          <h4 class="widget-title" 
                            *ngIf="scheduleDetails?.status !== statusList.COMPLETED && 
                                    scheduleDetails?.status !== statusList.REJECTED && !isTutor ">Actions</h4>
                          <h4 class="widget-title" 
                          *ngIf="scheduleDetails?.status !== statusList.COMPLETED && 
                                  scheduleDetails?.status !== statusList.REJECTED && scheduleDetails?.status !== statusList.CANCELLED && isTutor">Actions</h4>
                          <div class="statusaction_btns" *ngIf="isTutor">
                            <div>
                              <ng-container
                                *ngIf="scheduleDetails?.meetingStartUrl && scheduleDetails?.status !== statusList.COMPLETED">
                                <button class="btn btn-primary"><a href="{{scheduleDetails.meetingStartUrl}}"
                                    target="_blank">Start Meeting</a></button>
                              </ng-container>
                            </div>
                            <div *ngIf="statusList.REQUESTED === scheduleDetails?.status" class="mb-2">
                              <p>Total Amount</p>
                              <p-inputNumber inputId="integeronly" [(ngModel)]="totalAmountTobePaid" [min]="0">
                              </p-inputNumber>
                              <p class="text-danger"
                                *ngIf="totalAmountTobePaid < 1 || totalAmountTobePaid === null || totalAmountTobePaid === undefined">
                                Total Amount should be greater then zero
                              </p>
                            </div>
                            <ng-container *ngIf="statusList.REQUESTED === scheduleDetails?.status">
                              <button type="button" class="btn btn-primary btn-lg m-1"
                                [disabled]="totalAmountTobePaid < 1 || totalAmountTobePaid === null || totalAmountTobePaid === undefined"
                                (click)="acceptSchedule(statusList.ACCEPTED)">Accept</button>
                              <button type="button" class="btn btn-danger btn-lg m-1"
                                (click)="updateSchedule(statusList.REJECTED)">Reject</button>
                            </ng-container>

                            <ng-container *ngIf="statusList.ACCEPTED === scheduleDetails?.status">
                              <button type="button" class="btn btn-primary btn-lg m-1"
                                (click)="updateSchedule(statusList.CANCELLED)">Cancel</button>
                              <button type="button" class="btn btn-danger btn-lg m-1"
                                (click)="updateSchedule(statusList.REJECTED)">Reject</button>
                            </ng-container>
                            <ng-container *ngIf="statusList.REJECTED === scheduleDetails?.status">
                              <button type="button" class="btn btn-primary btn-lg m-1"
                                (click)="acceptSchedule(statusList.ACCEPTED)">Accept</button>
                              <!-- <button type="button" class="btn btn-danger btn-lg m-1"
                                (click)="updateSchedule(statusList.CANCELLED)">Cancel</button> -->
                            </ng-container>
                          </div>
                          <div *ngIf="!isTutor">
                            <div>
                              <ng-container
                                *ngIf="scheduleDetails?.meetingLink && scheduleDetails?.status !== statusList.COMPLETED">

                                <button class="btn btn-primary m-1"> <a href="{{scheduleDetails?.meetingLink}}"
                                    target="_blank">Join here</a></button>
                              </ng-container>
                            </div>
                            <div>
                              <button *ngIf="
                              scheduleDetails?.status === statusList.ACCEPTED
                            " type="button" (click)="navigateToPayment()"
                                class="btn btn-primary btn-lg m-1">Procced
                                To Payment</button>
                              <button type="button" *ngIf="
                              scheduleDetails?.status === statusList.REQUESTED || scheduleDetails?.status === statusList.ACCEPTED
                            " class="btn btn-danger btn-lg m-1"
                                (click)="updateSchedule(statusList.CANCELLED)">Cancel
                                Schedule</button>
                              <button type="button" *ngIf="
                              scheduleDetails?.status === statusList.CONFIRMED
                            " class="btn btn-success btn-lg m-1" (click)="updateSchedule(statusList.COMPLETED)">
                                Mark as completed</button>
                              <button type="button" *ngIf="
                              scheduleDetails?.status === statusList.CANCELLED
                            " class="btn btn-warning btn-lg m-1"
                                (click)="updateSchedule(statusList.REQUESTED)">Request Schedule</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- /Personal Details -->
          </div>
          <div class="tab-pane fade" id="notes" *ngIf="!isTutor">
            <div class="card">
              <div class="form-group card-body mb-0">
                <div class="d-flex justify-content-end mb-2">
                  <button class=" btn btn-lg  btn-primary" [disabled]="!notes" (click)="saveNotes()"> Save notes
                  </button>
                </div>
                <!-- <label class="form-control-label">Notes</label> -->
                <!-- <textarea type="text" rows="5" class="form-control" placeholder="Take Notes Here"
                  [(ngModel)]="notes"></textarea> -->
                <p-editor [(ngModel)]="notes" [style]="{ height: '320px' }"></p-editor>
                <div class="text-end mt-2">
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pass">
            <div class="mt-2 ">
              <div>
                <div class="card-body ">
                  <div class="attachments_wrapper">
                    <div class="" *ngIf="isTutor">
                      <app-fileuploader [clearAttachments]="clearAttachments"
                        (updatedFilesDescription)="updatedFilesDescription($event)"></app-fileuploader>
                    </div>
                    <div class="attachment" *ngFor="let attachment of scheduleDetails?.attachments; let index = index">
                      <div class="cancel_icon" *ngIf="isTutor" (click)="deleteAttachments(index)">
                        X
                      </div>
                      <a href="{{attachment?.url}}" target="_blank">
                      <div class="text-center">
                        <img
                        *ngIf="attachment.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' "
                        src="./../../../../../assets/Word-icon.png" alt="word-icon">
                      <img
                        *ngIf="attachment.type === 'application/vnd.ms-excel' || attachment.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || attachment.type === 'text/csv' "
                        src="./../../../../../assets/File-icon.svg" alt="word-icon">
                      <img *ngIf="attachment.type === 'application/pdf' " src="./../../../../../assets/Pdf-icon.svg"
                        alt="word-icon">

                      </div>
                        <div class="file-details">
                          <div class="file-name">{{attachment.name}}</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row text-center"
                *ngIf="(!scheduleDetails?.attachments || scheduleDetails?.attachments.length === 0) && !isTutor">
                <h4 class="text-danger p-4 col-12 col-md-6">No Attachments Found</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Student Card -->
      </div>
    </div>
  </div>
</div>

<p-dialog [header]="message" [modal]="true" [draggable]="false" [(visible)]="visible">
  <!-- <p class="mb-4">{{ message }}</p> -->
  <div>
    <div>
      <textarea class="form-control" cols="100" rows="2" [(ngModel)]="reason"></textarea>
    </div>
    <div class="d-flex justify-content-end">
      <button [disabled]="!reason" type="button" class="btn btn-primary btn-lg mt-2"
        (click)="cancelOrRejectScheduleReason()">Confirm</button>
    </div>
  </div>
</p-dialog>