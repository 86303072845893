import { Injectable } from '@angular/core';
import { HttpDataService } from './http-data/http-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {

  constructor(
    private httpDataService: HttpDataService
  ) { }

  savePrivacyPolicy(payload: any): Observable<any>{
    return this.httpDataService.post('company-details/save/privacyPolicy',payload);
  }

  saveAboutCompany(payload: any): Observable<any>{
    return this.httpDataService.post('company-details/save/aboutCompany',payload);
  }

  getPrivacyPolicy(): Observable<any>{
    return this.httpDataService.get('company-details/PrivacyPolicy')
  }

  getAboutCompany(): Observable<any>{
    return this.httpDataService.get('company-details/AboutCompany')
  }
}
