import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpDataService } from '../http-data/http-data.service';
import { StorageService } from '../storage-service/storage-service';
import { IUserInterface } from './user-interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpDataService : HttpDataService,
    private storageService:StorageService
  ) { 
  }

  userDetails!: IUserInterface;

  getLogedinUserDetails() : Observable<any>{
    return this.httpDataService.get(`user/${this.storageService.getDataFromLocalStorage('id')}`)
  }

  getUserDetails(userId: string): Observable<any>{
    return this.httpDataService.get(`user/${userId}`);
  }
}
