import { Injectable } from '@angular/core';
import { HttpDataService } from './http-data/http-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private httpDataService :HttpDataService) { }

  getUserNotification(alertId:string):Observable<any>{
    return this.httpDataService.get(`alerts/user/${alertId}`);
  }

  
  deleteNotifications(alertId:string):Observable<any>{
    return this.httpDataService.delete(`alerts/user/${alertId}`);
  }  

  deleteSelectedNotification(alertId:string):Observable<any>{
    return this.httpDataService.delete(`alerts/${alertId}`);
  }  
}
