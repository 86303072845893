import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeZoneConverter'
})
export class DateTimeZoneConverterPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(utcDate: string, targetTimeZone: string): string {
    const date = new Date(utcDate);
    const formattedDate = this.datePipe.transform(date, 'MMM dd, yyyy', targetTimeZone);
    return formattedDate || '';
  }

}
