import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, finalize, of } from 'rxjs';
import { HttpDataService } from 'src/app/shared/services/http-data/http-data.service';
import { IprofileUrl, IuserObject } from './student-profile.interface';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StudentProfileService {
  private profilePicture = new BehaviorSubject<string>('');
  constructor(
    private httpDataService:HttpDataService,
    private http:HttpClient
  ) { }

  updateUserDetails(payload:IuserObject , id:string):Observable<any>{
    return this.httpDataService.put(`user/${id}`,payload);
  }

  uploadFiletourl(fileName: string, fileType: string,   uploadType: string, userId:string ): Observable<any> {
    this.httpDataService.loadingCount =  this.httpDataService.loadingCount+1;

    if (userId && uploadType && fileType && fileName) {
    return this.http.get(`https://mt7jv6w5g9.execute-api.us-east-2.amazonaws.com/default/dev-get-singed-url?fileName=${fileName}&fileType=${fileType}&uploadType=${uploadType}&uid=${userId}`).pipe(
      finalize(()=>{
        this.httpDataService.loadingCount =  this.httpDataService.loadingCount -1;
      })
    );} else {
      return of(null);
    }
    }

    updateFileInS3(updateFileInS3:string,fileType:string,file:File) : Observable<any> {
      let loadingCount=0
      this.httpDataService.loadingCount =  this.httpDataService.loadingCount+1;
      if (updateFileInS3) {
      const headers = new HttpHeaders(
        { 'Content-Type':fileType,
          'Anonymous':''
        });
      return this.http.put(updateFileInS3,file,{headers}).pipe(
        finalize(()=>{
          this.httpDataService.loadingCount =  this.httpDataService.loadingCount -1;
        })
      );} else {
        return of(null);
      }
    }
    
    saveProfileUrl(payload:IprofileUrl , id:string) :Observable<any>{
      return this.httpDataService.put(`user/${id}`,payload);
    }

    setProfilePicture(value: string) {
      this.profilePicture.next(value);
    }
  
    getProfilePicture() {
      return this.profilePicture.asObservable();
    }

    getStudentProfileScore(userId : string):Observable<any>{
      return this.httpDataService.get(`user/profilescore/${userId}`)
    }
  
}
