import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpDataService } from 'src/app/shared/services/http-data/http-data.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpdataService: HttpDataService) { }

  getAllStudents(): Observable<any> {
    return this.httpdataService.get('user/all-students');
  }

  getAllUserCounts(): Observable<any> {
    return this.httpdataService.get('user/users-count');
  }

  getAllTutors(): Observable<any> {
    return this.httpdataService.get('user/all-tutors');
  }
  
  getTotalRevenue(): Observable<any> {
    return this.httpdataService.get('payments/getTotalAmount');
  }

  getMonthlyStatistics(year:string): Observable<any> {
    return this.httpdataService.get(`payments/monthlyStatistics/${year}`);
  }

  deactivateUser(userId:string):Observable<any>{
    return this.httpdataService.put(`user/deactivate/${userId}`,{});
  }

  activateUser(userId:string, payload:any ):Observable<any>{
    return this.httpdataService.put(`user/activate/${userId}`,payload);
  }
  getTotalTutoringHours():Observable<any>{
    return this.httpdataService.get(`schedule/tutors-schedule-hours`)
  }
}
